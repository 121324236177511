// ==============================
// Custom style
// 自定义样式
// ==============================
.single .content>h2>.header-mark::before {
    content: '|';
}
  
.single .content>h2{
    color:#2376b7;
}

.single .content>h3{
    color:#2376b7;
}

